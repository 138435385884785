<template>
  <v-container fluid class="mb-16">
    <p class="text-h5 text-left">안내메일 문구 관리</p>

    <v-row>
      <v-col cols="6">

        <table class="mt-6" style="width: 100%">
          <colgroup>
            <col style="width: 10%" />
            <col />
          </colgroup>
          <tbody>
          <tr>
            <th colspan="2" class="td_dark_blue">계약체결 안내 문구</th>
          </tr>
          <tr>
            <th class="td_dark_blue">알림제목</th>
            <td class="td_blue cen bo pa-4">
              <v-textarea
                  v-model="item1.alarmSubject"
                  outlined
                  hide-details
                  auto-grow
                  rows="2"
              >
              </v-textarea>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">알림본문</th>
            <td class="td_blue cen bo pa-4">
              <v-textarea
                  v-model="item1.alarmContent"
                  outlined
                  hide-details
                  auto-grow
                  rows="5"
              >
              </v-textarea>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">첨부파일</th>
            <td class="td_blue bo pa-4 text-left">
              <v-file-input
                  v-model="files1"
                  multiple
                  outlined
                  hide-details
                  chips
                  clearable
                  class="mb-2"
              />
              <div
                  v-for="(file, index) in item1.files"
                  :key="index"
              >
                <v-chip
                    label
                    outlined
                    close
                    class="ma-1"
                >
                  {{ file.convFileName }}
                </v-chip>
              </div>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">참고</th>
            <td class="td_blue bo pa-4 text-left">
              <div>사용가능 템플릿: <b>신청번호, 관리번호, 기술이전책임자, 업체명, 대표기술유형</b></div>
              <div>사용 예> [기술이전] 신청번호 <b>${신청번호}</b> 계약체결 알림</div>
            </td>
          </tr>
          </tbody>

        </table>
        <div class="text-right mt-2">
          <v-btn
            small
            class="mr-2"
            elevation="0"
            color="primary"
            @click="saveItem1"
          >
            저장
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6">

        <table class="mt-6" style="width: 100%">
          <colgroup>
            <col style="width: 10%" />
            <col />
          </colgroup>
          <tbody>
          <tr>
            <th colspan="2" class="td_dark_blue">기술료입급 안내 문구</th>
          </tr>
          <tr>
            <th class="td_dark_blue">알림제목</th>
            <td class="td_blue cen bo pa-4">
              <v-textarea
                  v-model="item2.alarmSubject"
                  outlined
                  hide-details
                  auto-grow
                  rows="2"
              >
              </v-textarea>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">알림본문</th>
            <td class="td_blue cen bo pa-4">
              <v-textarea
                  v-model="item2.alarmContent"
                  outlined
                  hide-details
                  auto-grow
                  rows="5"
              >
              </v-textarea>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">첨부파일</th>
            <td class="td_blue bo pa-4 text-left">
              <v-file-input
                  v-model="files2"
                  outlined
                  hide-details
                  chips
                  clearable
                  class="mb-2"
              />
              <div
                  v-for="(file, index) in item2.files"
                  :key="index"
              >
                <v-chip
                    label
                    outlined
                    close
                    class="ma-1"
                >
                  {{ file.convFileName }}
                </v-chip>s
              </div>
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue">참고</th>
            <td class="td_blue bo pa-4 text-left"><div>사용가능 템플릿: <b>신청번호, 관리번호, 기술이전책임자, 업체명, 대표기술유형</b></div>
              <div>사용 예> [기술이전] 신청번호 <b>${신청번호}</b> 기술료입금 알림</div>
            </td>
          </tr>
          </tbody>

        </table>
        <div class="text-right mt-2">
          <v-btn
            small
            class="mr-2"
            elevation="0"
            color="primary"
            @click="saveItem2"
          >
            저장
          </v-btn>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AlarmManage',
  data() {
    return {
      item1: {
				alarmType: 'CI',
				alarmSubject: null,
				alarmContent: null,
        files: [],
      },
      item2: {
				alarmType: 'TD',
				alarmSubject: null,
				alarmContent: null,
        files: [],
      },
      files1: [],
      files2: [],
      loading: false,
    }
  },
  mounted() {
    this.search()
  },
  methods: {
		search() {
			this.loading = true
			this.$http.get('/api/sys/alarm')
				.then(({ data }) => {
          for (let d of data) {
            if (d.alarmType === 'CI') {
              this.item1 = d
            } else if (d.alarmType === 'TD') {
              this.item2 = d
            }
          }
				})
				.finally(() => this.loading = false)
		},
    saveItem1() {
      let formData = new FormData();
      for (let file of this.files1) {
        formData.append("files", file)
      }

      formData.append("param", new Blob([ JSON.stringify(this.item1) ], {type : "application/json"}))

      this.$http.post('/api/sys/alarm', formData)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.search()
        }
      })
    },
    saveItem2() {
      let formData = new FormData();
      for (let file of this.files2) {
        formData.append("files", file)
      }

      formData.append("param", new Blob([ JSON.stringify(this.item2) ], {type : "application/json"}))

      this.$http.post('/api/sys/alarm', formData)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.search()
        }
      })
    },
  }
}
</script>
